const absadm='https://localhost/tomat/4dm1n01102017';
const abs='https://localhost/tomat';
// const absadm='https://tokomaterial.co.id/4dm1n01102017';
// const abs='https://tokomaterial.co.id';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const seller_id = urlParams.get("seller_id");
const id_site_survey = urlParams.get("id");
const msg = urlParams.get("msg");
const page = urlParams.get("page");
