$('.img-holder-menuweb').imageScroll({
	speed: 0.8,
	coverRatio: 0.4,
	mediaWidth: 1920,
  mediaHeight: 320,
		// image: null,
		// imageAttribute: 'image',
		// container: $('body'),
		// windowObject: $(window),
		// holderClass: 'imageHolder',
		// imgClass: 'img-holder-img',
		// holderMinHeight: 200,
		// holderMaxHeight: null,
    // extraHeight: 100,
		// parallax: true,
		// touch: false
});
$('.img-holder-search').imageScroll({
	speed: 0.8,
	coverRatio: 0.8,
	mediaWidth: 2000,
  mediaHeight: 1000,
});
$('.img-holder-index-contact').imageScroll({
	speed: 0.8,
	coverRatio: 0.5,
	mediaWidth: 1800,
  mediaHeight: 600,
});
$('.img-holder-home').imageScroll({
	speed: 0.9,
	coverRatio: 0.5,
	mediaWidth: 2000,
  mediaHeight: 500,
});
