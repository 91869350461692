$('.bwWrapper').BlackAndWhite({
  hoverEffect:true,
  webworkerPath: '../../js/jQuery.BlackAndWhite-master/src/',
  invertHoverEffect: true,
  intensity:1,
  onImageReady:function(img){
    $(img).parent().animate({
      opacity:1
    });
  }
});
