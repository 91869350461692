$.scrollUp({
	scrollName: 'scrollUp',
	topDistance: '300', // Distance from top before showing element (px)
	topSpeed: 300, // Speed back to top (ms)
	animation: 'slide', // Fade, slide, none
	animationInSpeed: 200, // Animation in speed (ms)
	animationOutSpeed: 200, // Animation out speed (ms)
	scrollText: '', // Text for element:Scroll to top
	scrollImg: false,
	activeOverlay: false // Set CSS color to display scrollUp active point, e.g '#00FFFF'
});
