$("#adm-member-seller").dataTable({
  "responsive": true,
  "autoWidth": false,
  "stateSave": true,
  "bProcessing": true,
  "bServerSide": true,
  //disable order dan searching pada tombol aksi
  columnDefs: [
    {
      "targets": [0, 7],
      "orderable": false,
      "searchable": false,
    },
  ],
  ajax: {
    url: "../4dm1n01102017/member_seller/data.php",
    type: "post", // method  , by default get
    //bisa kirim data ke server
    data: function ( d ) {
      d.jurusan = "3223";
    },
    error: function (xhr, error, thrown) {
      console.log(xhr);
    },
  },
});
