// mixed
// $('#main-menu-alt').css({
// 	'padding-top':'20px',
// 	'float':'right',
// }); 
// $(window).scroll(function() {
// 	if ($(window).scrollTop() > 200) {
// 		$('.top_logo').css({'height': '25px'}).animate('slow'); 
// 		$('.top-blue').slideUp('fast'); 
// 		$('#main-menu-alt').css({'padding-top':'0'}); 
// 		$('#cart-flying-div').fadeIn('fast'); 
// 		// $('.top-menu-logo').css({'background':'url(<?=abspath;?>images/bgtrans1.png)'}); 
// 		// $('.menuweb-smartmenu').css({'color':'#444'}); 
// 	} else {
// 		$('.top_logo').css({'height': '80px'}).animate('slow'); 
// 		$('.top-blue').slideDown('fast'); 
// 		$('#main-menu-alt').css({'padding-top':'20px'}); 
// 		$('#cart-flying-div').fadeOut('fast'); 
// 		// $('.top-menu-logo').css({'background':'transparent'}); 
// 		// $('.menuweb-smartmenu').css({'color':'#eee'}); 
// 	}
// });

// ui
$(".accordion").accordion({ 
	// <? 
	// if ($_GET['sid']<=68) $active=0;
	// else if ($_GET['sid']<=71) $active=1;
	// else if ($_GET['sid']<=77) $active=2;
	// ?>
	// active : <?=$active;?>,
	heightStyle: "content", collapsible:true, header:"h3", autoHeight:false, navigation:true, //active : false, 
});
$(".datepicker").datepicker();
$( ".tabs" ).tabs({
	ajaxOptions: {
		error: function( xhr, status, index, anchor ) {
			$( anchor.hash ).html(
				"Couldn't load this tab. We'll try to fix this as soon as possible. " +
				"If this wouldn't be a demo." );
		}
	}
});
$( "#draggable" ).draggable();
//Initialize Select2 Elements
$(".select2").select2();
//Initialize Select2 Elements
$(".select2bs4").select2({
  // theme: "bootstrap4",
});
$('.js-example-basic-single').select2();

// GridLoadingEffects
// new AnimOnScroll( document.getElementById('grid'), {
// 	minDuration : 0.4,
// 	maxDuration : 0.7,
// 	viewportFactor : 0.2
// });
// new AnimOnScroll( document.getElementById('grid2'), {
// 	minDuration : 0.4,
// 	maxDuration : 0.7,
// 	viewportFactor : 0.2
// });
