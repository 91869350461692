var owl = $("#owl-tokomaterial-official");
owl.owlCarousel({
	items : 5, //10 items above 1000px browser width
  itemsDesktop : [1000, 4], //5 items between 1000px and 901px
  itemsDesktopSmall : [900, 3], // betweem 900px and 601px
  itemsTablet: [600, 2], //2 items between 600 and 0
  itemsMobile : false, // itemsMobile disabled - inherit from itemsTablet option
	pagination : false,
  paginationNumbers: true,
	autoPlay : true,
  slideSpeed : 800,
  paginationSpeed : 1000,
  rewindSpeed : 1200,
	autoHeight : false,
});
// Custom Navigation Events
$(".next").click(function(){
  owl.trigger('owl.next');
})
$(".prev").click(function(){
  owl.trigger('owl.prev');
})
$(".play").click(function(){
  owl.trigger('owl.play',1000); //owl.play event accept autoPlay speed as second parameter
})
$(".stop").click(function(){
  owl.trigger('owl.stop');
})
