const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const seller_id = urlParams.get("seller_id");
const id = urlParams.get("id");
$("#frontend-ulasan").dataTable({
  "responsive": true,
  "autoWidth": false,
  "stateSave": true,
  "bProcessing": true,
  "bServerSide": true,
  //disable order dan searching pada tombol aksi
  "columnDefs": [
    {
      "targets": [],
      "orderable": false,
      "searchable": false,
    },
  ],
  ajax: {
    url: "../../components/data-review-product.php?seller_id="+seller_id+"&id="+id,
    type: "post", // method  , by default get
    //bisa kirim data ke server
    // data: function ( d ) {
    //   d.jurusan = "3223";
    // },
    error: function (xhr, error, thrown) {
      console.log(xhr);
    },
  },
});

