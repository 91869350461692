$('.readmore').readmore({
  moreLink : '<a class="moredetail" href="#">Tampilkan lebih banyak</a>',
  lessLink : '<a class="moredetail" href="#">Tampilkan lebih sedikit</a>',
  collapsedHeight : 240,
  speed : 400,
  afterToggle: function(trigger, element, expanded) {
    if(! expanded) { // The "Close" link was clicked
      $('html, body').animate({scrollTop: $(element).offset().top}, {duration: 100});
    }
  }
});
// $('.readmore').readmore({speed: 500});
